import { store } from "../config/reduxConfig";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { getCurrentUserEmail } from "../lib/localData";
import { ErrorWithResponse, checkStatus, parseJSON } from "./fetcher";

const getFetchParamsUserEmail = (email: string | null) => {
  const state: { currentUser?: User } = store.getState();

  if (email) {
    return email;
  }

  /* Use current user state */
  if (state && state.currentUser && state.currentUser.email) {
    return state.currentUser.email;
  }

  /* Use local storage */
  if (getCurrentUserEmail()) {
    return getCurrentUserEmail();
  }

  return "";
};

interface ConstructFetchParamsInput {
  authorizationRequired?: boolean;
  connectedAccountToken?: string;
  email: string | null;
  method: string;
  payloadData?: RequestInit;
}

function constructFetchParams({
  authorizationRequired,
  connectedAccountToken,
  email = null,
  method,
  payloadData = {},
}: ConstructFetchParamsInput) {
  if (!payloadData["headers"]) {
    payloadData["headers"] = getDefaultHeaders();
  }

  payloadData["method"] = method;

  // When submitting multipart/form-data, allow fetch to generate the full content type based on the body.
  // Otherwise the header may be incomplete.
  // https://github.com/JakeChampion/fetch/issues/505#issuecomment-293064470
  // https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
  if (!payloadData["headers"]["Content-Type"]) {
    payloadData["headers"]["Content-Type"] = "application/json";
  } else if (payloadData["headers"]["Content-Type"] === "multipart/form-data") {
    delete payloadData["headers"]["Content-Type"];
  }

  payloadData["headers"]["X-VIMCAL-USER-CLIENT"] = "web";

  const userEmail = getFetchParamsUserEmail(email);
  if (authorizationRequired && userEmail) {
    payloadData["headers"]["X-VIMCAL-USER-EMAIL"] = userEmail;
    payloadData["credentials"] = "include";

    /* Don't add the header if token doesn't exist since backend reads null as a string */
    if (connectedAccountToken) {
      payloadData["headers"]["X-VIMCAL-CONNECTED-ACCOUNT-TOKEN"] = connectedAccountToken;
    }
  }

  return payloadData;
}

interface VimcalFetchInput extends ConstructFetchParamsInput {
  onError?: (error: ErrorWithResponse) => void;
  url: string;
}

async function vimcalFetch<T>({
  authorizationRequired = true,
  connectedAccountToken, // optional
  email = null,
  method,
  onError, // optional
  payloadData = {}, // optional
  url,
}: VimcalFetchInput) {
  try {
    const response = await fetch(
      url,
      constructFetchParams({
        authorizationRequired,
        connectedAccountToken,
        email,
        method,
        payloadData,
      }),
    );
    const checkedResponse = await checkStatus(response);

    return parseJSON<T>(checkedResponse);
  } catch(error: any) {
    if (onError) {
      onError(error);
    }
  }
}

type FetcherParams = Omit<VimcalFetchInput, "method">;

export const fetcherDelete = <T = Record<string, never>>(params: FetcherParams) => vimcalFetch<T>({ ...params, method: "DELETE" });
export const fetcherGet = <T = Record<string, never>>(params: FetcherParams) => vimcalFetch<T>({ ...params, method: "GET" });
export const fetcherPatch = <T = Record<string, never>>(params: FetcherParams) => vimcalFetch<T>({ ...params, method: "PATCH" });
export const fetcherPost = <T = Record<string, never>>(params: FetcherParams) => vimcalFetch<T>({ ...params, method: "POST" });
