global.$RefreshReg$ = () => { }; // eslint-disable-line no-restricted-globals
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24
global.$RefreshSig$$ = () => () => { }; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */

import {
  PHONE_MEETING_ICON,
  zoomImageURL,
  hangoutIconURL,
  WHATS_APP_ICON,
} from "./globalVariables";
import {
  formatRFCPhoneNumber,
  formatPhoneNumberHumanReadable,
  getPhoneNumberString,
  sendMessageToSentry,
} from "./commonUsefulFunctions";
import {
  isDefaultZoomPersonalLink,
  getZoomPersonalLink,
  getZoomMeetingDetails,
  convertConferencingStringToBackendValue,
  isValidCustomConferencing,
} from "../lib/conferencing";
import { getEventConferenceURL } from "./eventResourceAccessors";
import { OUTLOOK_CONFERENCING } from "../resources/outlookVariables";
import {
  BACKEND_OUTLOOK_CONFERENCING,
  isOutlookConferencingOption,
  isOutlookUser,
} from "../lib/outlookFunctions";
import { convertOutlookConferencingToHumanReadable } from "../lib/outlookFunctions";
import { getAccountReferralCode, getUserName } from "../lib/userFunctions";
import { getOutlookAllowedConferencing } from "./calendarAccessors";
import { getZoomHostEmail, getZoomJoinURL } from "../lib/zoomFunctions";
import { isGoogleUser } from "./appFunctions";
import { isEmptyObjectOrFalsey } from "./typeGuards";
import { getCustomConferencingName, getCustomConferencingURL } from "../lib/settingsFunctions";
import { capitalizeFirstLetter, WHATSAPP_BASE_URL } from "../lib/stringFunctions";
import { createUUID } from "./randomFunctions";

export const ATTENDEE_EVENT_ATTENDING = "accepted";
export const ATTENDEE_EVENT_TENTATIVE = "tentative";
export const ATTENDEE_EVENT_NEEDS_ACTION = "needsAction";
export const ATTENDEE_EVENT_DECLINED = "declined";
export const SELF_RESPONSE_CONFIRMED = "confirmed";

export const PERSIST_KEY = "Vimcal";


export const GOOGLE_UPDATES = {
  ALL: "all",
  NONE: "none",
  EXTERNAL: "externalOnly",
};

// We default to external so that Outlook users get the event on calendar
export const DEFAULT_GOOGLE_DO_NOT_SEND_UPDATE = GOOGLE_UPDATES.EXTERNAL;

// TODO: RENAME THIS??? IT'S USED TO COMPARE CANCELLED EVENTS, NOT JUST CANCELLED SELF RESPONSES
export const SELF_RESPONSE_CANCELLED = "cancelled";
export const TODAY = "TODAY";
export const PREVIOUS = "PREV";
export const NEXT = "NEXT";
const MODIFY_EVENTS_STRING = "Modify events";
const INVITE_OTHERS_STRING = "Invite others";
const SEE_GUEST_LIST_STRING = "See guest list";

export const GOOGLE_HANGOUT_STRING = "Google Meet";
export const ZOOM_STRING = "Zoom";
export const PHONE_NUMBER_CONFERENCE = "Phone";
export const NO_CONFERENCE_STRING = "No conferencing";
export const WHATS_APP_STRING = "WhatsApp";
export const CUSTOM_CONFERENCING_STRING = "Custom conferencing";

export const CONFERENCING_TYPE = {
  GOOGLE_HANGOUT_STRING: GOOGLE_HANGOUT_STRING,
  ZOOM_STRING: ZOOM_STRING,
  PHONE_NUMBER_CONFERENCE: PHONE_NUMBER_CONFERENCE,
  NO_CONFERENCE_STRING: NO_CONFERENCE_STRING,
  WHATS_APP_STRING: WHATS_APP_STRING,
  CUSTOM_CONFERENCING_STRING: CUSTOM_CONFERENCING_STRING,
};

let MINUTES_STRING = "minutes";
let HOURS_STRING = "hours";
let DAYS_STRING = "days";
export const DAY_STRING = "day";
export const WEEKS_STRING = "weeks";
let MONTHS_STRING = "months";
let YEARS_STRING = "years";
let CONFIRMED_ATTENDANCE = "confirmed";
let NEVER = "Never";
let AFTER = "After";
let ON = "On";
let ON_DAY_X = "on day x";
let NTH_DAY_OF_WEEK = "on nth day of week";
let POPUP = "popup";
let EMAIL = "email";
let DOES_NOT_REPEAT = "Does not repeat";
let DAILY = "Daily";
let WEEKLY = "Weekly";
let MONTHLY = "Monthly";
let ANNUALLY = "Annually";
let CUSTOM = "Custom";
export const TEMPORARY = "temporary";
export const AVAILABILITY = "availability";
let ZOOM_US = "zoom.us";
let TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
let SELECT_TIMEZONE = "Select time zone";
let ADD_ATTENDEE_PLACEHOLDER = "Add attendees";
let LOCATION_AUTO_COMPLETE = "autocomplete";
export const VISIBILITY_PRIVATE = "private";
let SUMMARY_BUSY = "busy";
let NO_TITLE = "(No title)";
export const EDIT_RECURRING_INSTANCE_ONLY = "This event";
export const EDIT_RECURRING_FOLLOWING_EVENTS = "This and following events";
export const EDIT_RECURRING_ALL_INSTANCES = "All events";

export const TRANSPARENCY_RESOURCE = "transparency";
export const COLOR_ID_RESOURCE = "colorId";

export const MOMENT_FORMAT_Z = "YYYY-MM-DDTHH:mm:ss[Z]";

// only need to put in left handed hot keys
export const TAKEN_LETTER_LIST = [
  "z",
  "g",
  "u",
  "i",
  "t",
  "e",
  "c",
  "f",
  "a",
  "v",
  "j",
  "k",
  "d",
  "w",
  "r",
  "4",
  "d",
  4,
  "p",
  "l",
  "m",
  "7",
  7,
  "n",
];
let ALL_LETTERS = [
  "a",
  "b",
  "c",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const DEFAULT = "default";
export const DEFAULT_VISIBILITY = "Default visibility";
export const PUBLIC = "public";
export const PRIVATE = "private";
export const CONFIDENTIAL = "confidential";
export const FREE = "Free";
export const BUSY = "Busy";
export const FREE_DURING_EVENT = "transparent";
export const BUSY_DURING_EVENT = "opaque";
export const OUT_OF_OFFICE_DURING_EVENT = "outOfOffice";
export const EVENT_TEMPLATE = "creatingEventTemplate";
export const EVENT_TEMPLATE_FROM_EVENT = "createTemplateFromEvent";
export const EVENT_COLOR_SECTION = "eventColor";
export const TEXT_TEMPLATE = "creatingTextTemplate";
export const TEMPLATE = "template";
export const UPDATE_TEMPLATE = "updateTemplate";
export const EDIT = "edit";
export const CREATE_NEW = "createNew";
export const RECURRING_EVENT = "recurringEvent";
export const TEMPLATE_DELETED = "template_deleted";
export const TITLE_SECTION = "titleSection";
export const TIME_SECTION = "timeSection";
export const LOCATION_SECTION = "locationSection";
export const CONFERENCE_SECTION = "conferenceSection";
export const ROOMS_SECTION = "roomsSection";
export const ATTENDEE_SECTION = "attendeeSection";
export const DETAIL_SECTION = "detailSection";
export const AVAILABILITY_SECTION = "availabilitySection";
export const REMINDER_SECTION = "reminderSection";

export const DEFAULT_EVENT_TYPE = "default";
export const OUT_OF_OFFICE_EVENT_TYPE = "outOfOffice";

export const OUT_OF_OFFICE_AUTO_DECLINE_ALL = "declineAllConflictingInvitations";
export const OUT_OF_OFFICE_AUTO_DECLINE_NEW = "declineOnlyNewConflictingInvitations";
export const OUT_OF_OFFICE_AUTO_DECLINE_NONE = "declineNone";
export const OUT_OF_OFFICE_DEFAULT_DECLINE_MESSAGE = "Declined because I am out of office";

// https://developers.google.com/calendar/api/v3/reference/acl
export const NOT_SHARED_ROLE = "none";
export const FREE_BUSY_READER_ROLE = "freeBusyReader";
export const READER_ROLE = "reader";
export const WRITER_ROLE = "writer";
export const OWNER_ROLE = "owner";
export const EDITABLE_ROLES = [WRITER_ROLE, OWNER_ROLE];
export function determinePermissionLabel(permission) {
  switch (permission) {
    case FREE_BUSY_READER_ROLE:
      return "See only free/busy (hide details)";
    case READER_ROLE:
      return "See all event details";
    case WRITER_ROLE:
      return "Make changes to events";
    case OWNER_ROLE:
      return "Make changes and manage sharing";
    default:
      return "See all event details";
  }
}
export const ALL_ROLES = [
  {
    value: FREE_BUSY_READER_ROLE,
    label: determinePermissionLabel(FREE_BUSY_READER_ROLE),
  },
  { value: READER_ROLE, label: determinePermissionLabel(READER_ROLE) },
  { value: WRITER_ROLE, label: determinePermissionLabel(WRITER_ROLE) },
  { value: OWNER_ROLE, label: determinePermissionLabel(OWNER_ROLE) },
];

export const VIMCAL_SIGNATURE = "Created with Vimcal.com";
export function getVimcalRichTextSignature(masterAccount) {
  const referralCode = getAccountReferralCode(masterAccount);
  let url = "https://www.vimcal.com/";
  if (referralCode) {
    url += `?referral_code=${referralCode}`;
  }
  return `Created with <a href="${url}" rel="noopener noreferrer" target="_blank">Vimcal.com</a>`;
}

export function getVimcalPTagSignature(masterAccount) {
  return `<div><br></div><div><br></div><div><br></div><div>${getVimcalRichTextSignature(masterAccount)}</div>`;
}

export const BACKEND_ZOOM = "zoom";
export const BACKEND_HANGOUT = "google_hangouts";
export const BACKEND_PHONE = "phone";
export const BACKEND_WHATS_APP = "whatsapp";
export const BACKEND_NO_CONFERENCING = "none";
export const BACKEND_CUSTOM_CONFERENCING = "custom";

export const GOOGLE_WEEK_START = "weekStart";
export const GOOGLE_SHOW_DECLINED_EVENTS = "showDeclinedEvents";
export const GOOGLE_24_HOUR_FORMAT = "format24HourTime";
export const DATE_TIME_24_HOUR_FORMAT = "HH:mm"; // backwards compatible with date-fns and moment
export const DATE_TIME_12_HOUR_FORMAT = "h:mm aa";
export const GOOGLE_DATE_FIELD_ORDER = "dateFieldOrder";
export const MOMENT_DMY_DATE_FORMAT = "DMY";
export const MOMENT_MDY_DATE_FORMAT = "MDY";
export const MOMENT_YMD_DATE_FORMAT = "YMD";

export const GOOGLE_DMY_FORMAT = "DMY";
export const GOOGLE_MDY_FORMAT = "MDY";
export const GOOGLE_YMD_FORMAT = "YMD";

export function whichConferenceOption({ currentUser, event, calendar }) {
  if (!currentUser?.settings) {
    return { conference: GOOGLE_HANGOUT_STRING, link: null };
  }

  const { default_conferencing_option, default_phone_option } =
    currentUser.settings;

  const getDefaultUserOutlookConferencing = () => {
    switch (getOutlookAllowedConferencing(calendar)) {
      case OUTLOOK_CONFERENCING.teamsForBusiness:
        return { conference: OUTLOOK_CONFERENCING.teamsForBusiness };
      case OUTLOOK_CONFERENCING.skypeForBusiness:
        return { conference: OUTLOOK_CONFERENCING.skypeForBusiness };
      case OUTLOOK_CONFERENCING.skypeForConsumer:
        return { conference: OUTLOOK_CONFERENCING.skypeForConsumer };
      default:
        return { conference: NO_CONFERENCE_STRING, link: null };
    }
  }

  const getDefaultUserGoogleConferencing = () => {
    return {
      conference: GOOGLE_HANGOUT_STRING,
      link: event ? getEventConferenceURL(event) : null,
    };
  }

  switch (default_conferencing_option) {
    case BACKEND_ZOOM:
      return { conference: ZOOM_STRING, link: currentUser.zoom_link };
    case BACKEND_HANGOUT:
      if (isOutlookUser(currentUser)) {
        return getDefaultUserOutlookConferencing();
      }
      return getDefaultUserGoogleConferencing();
    case BACKEND_PHONE:
      if (default_phone_option === BACKEND_WHATS_APP) {
        return {
          conference: WHATS_APP_STRING,
          link: `${WHATSAPP_BASE_URL}${getPhoneNumberString(
            currentUser.phone_number,
            currentUser.phone_region_code
          )}`,
        };
      }
      return { conference: PHONE_NUMBER_CONFERENCE, link: null };
    case BACKEND_CUSTOM_CONFERENCING:
      if (isValidCustomConferencing(currentUser)) {
        return {
          conference: getCustomConferencingName({ user: currentUser }),
          link: getCustomConferencingURL({ user: currentUser }),
        };
      } else {
        return { conference: NO_CONFERENCE_STRING, link: null };
      }
    case BACKEND_OUTLOOK_CONFERENCING:
      if (isGoogleUser(currentUser)) {
        return getDefaultUserGoogleConferencing();
      }
      return getDefaultUserOutlookConferencing();
    default:
      return { conference: NO_CONFERENCE_STRING, link: null };
  }
}

export const ZOOM_CONFERENCING_OPTION = {
  value: BACKEND_ZOOM,
  label: ZOOM_STRING,
};

export const HANGOUT_CONFERENCING_OPTION = {
  value: BACKEND_HANGOUT,
  label: GOOGLE_HANGOUT_STRING,
};

export const PHONE_CONFERENCING_OPTION = {
  value: BACKEND_PHONE,
  label: PHONE_NUMBER_CONFERENCE,
};
export const NO_CONFERENCING_OPTION = {
  value: BACKEND_NO_CONFERENCING,
  label: NO_CONFERENCE_STRING,
};
export const WHATS_APP_CONFERENCING_OPTION = {
  value: BACKEND_WHATS_APP,
  label: WHATS_APP_STRING,
};

export function renderDefaultSignatureWithEmptyLinesAbove(masterAccount) {
  return `${renderEmptyLineString()}${renderEmptyLineString()}${renderEmptyLineString()}<p>${getVimcalRichTextSignature(masterAccount)}</p>`;
}

export function renderEmptyLineString() {
  return `<p><br></p>`;
}

export const conferenceSolutions = {
  zoom: {
    key: {
      type: "addOn",
    },
    name: "Zoom Meeting",
    iconUri: zoomImageURL,
  },
  phone: {
    key: {
      type: "addOn",
    },
    name: "phone call",
    iconUri: PHONE_MEETING_ICON,
  },
};

//https://developers.google.com/calendar/api/guides/create-events
const GOOGLE_MEET_CONFERENCING_TYPES = [
  "hangoutsMeet",
  "eventHangout",
  "eventNamedHangout", // deprecated according to api doc
];

function createZoomPersonalLinkConferenceData({ user, schedulers = {}, masterAccount }) {
  const zoomPersonalLink = getZoomPersonalLink({ user, schedulers, masterAccount });

  if (isEmptyObjectOrFalsey(user)) {
    return null;
  } else if (!zoomPersonalLink) {
    sendMessageToSentry("Empty currentUser zoom personal link");
    return null;
  }

  return {
    entryPoints: [
      {
        entryPointType: "video",
        uri: zoomPersonalLink,
        label: zoomPersonalLink,
      },
    ],
    conferenceSolution: conferenceSolutions.zoom,
    notes: null,
  };
}

export const createConferenceData = (
  { conference, currentUser, zoomMeeting, isTemplate, location, masterAccount },
  schedulers = {}
) => {
  // See https://developers.google.com/calendar/v3/reference/events#conferenceData
  conference = convertConferencingStringToBackendValue(conference, currentUser);
  switch (conference) {
    case BACKEND_HANGOUT:
      return {
        createRequest: {
          requestId: createUUID(),
        },
      };
    case BACKEND_ZOOM:
      if (isTemplate) {
        // use isTemplateZoomConferencing in commonUsefulFunctions to determine if it's template zoom conferencing
        return {
          conferenceType: ZOOM_STRING,
          isTemplate: true,
        };
      }
      return createZoomConferencing({
        user: currentUser,
        zoomMeeting,
        where: location,
        schedulers,
        masterAccount
      });
    case BACKEND_PHONE: {
      if (isTemplate) {
        return {
          conferenceType: PHONE_NUMBER_CONFERENCE,
          isTemplate: true,
        };
      }
      return createPhoneConferenceData({ currentUser, masterAccount });
    }
    case BACKEND_WHATS_APP: {
      if (isTemplate) {
        return {
          conferenceType: WHATS_APP_STRING,
          isTemplate: true,
        };
      }

      return createWhatsAppConferenceData({ currentUser, masterAccount });
    }
    case BACKEND_CUSTOM_CONFERENCING: {
      if (isTemplate) {
        return {
          conferenceType: CUSTOM_CONFERENCING_STRING,
          isTemplate: true,
        };
      }
      return createCustomConferencing(currentUser);
    }
    case OUTLOOK_CONFERENCING.skypeForBusiness:
      if (isTemplate) {
        return {
          conferenceType: OUTLOOK_CONFERENCING.skypeForBusiness,
          isTemplate: true,
        };
      }
      break;
    case OUTLOOK_CONFERENCING.teamsForBusiness:
      if (isTemplate) {
        return {
          conferenceType: OUTLOOK_CONFERENCING.teamsForBusiness,
          isTemplate: true,
        };
      }
      break;
    case OUTLOOK_CONFERENCING.skypeForConsumer:
      if (isTemplate) {
        return {
          conferenceType: OUTLOOK_CONFERENCING.skypeForConsumer,
          isTemplate: true,
        };
      }
      break;
    case BACKEND_NO_CONFERENCING:
      return null;
    default:
      sendMessageToSentry(
        "Unrecognized conference:",
        conference || "No conference data"
      );
      return null;
  }
};

export const conferencingDescriptor = (conferencingBackend, currentUser) => {
  if (isOutlookConferencingOption(conferencingBackend)) {
    return convertOutlookConferencingToHumanReadable(conferencingBackend);
  }

  switch (conferencingBackend) {
    case BACKEND_ZOOM:
      return "Zoom";
    case BACKEND_HANGOUT:
      return GOOGLE_HANGOUT_STRING;
    case BACKEND_PHONE:
      return "Phone call";
    case BACKEND_WHATS_APP:
      return WHATS_APP_STRING;
    case BACKEND_CUSTOM_CONFERENCING:
      return getCustomConferencingName({ user: currentUser });
    default:
      return null;
  }
};

const BACKEND_CONFERENCE_ICONS = {
  BACKEND_ZOOM: zoomImageURL,
  BACKEND_HANGOUT: hangoutIconURL,
  BACKEND_PHONE: PHONE_MEETING_ICON,
};

export const backendConferenceIconUrl = (backendConferencing) =>
  BACKEND_CONFERENCE_ICONS[backendConferencing];

export function createPhoneConferenceData({ currentUser, masterAccount }) {
  const regionCode = currentUser.phone_region_code;
  const number = currentUser.phone_number;
  const { firstName } = getUserName({ user: currentUser, masterAccount });
  return {
    entryPoints: [
      {
        entryPointType: "phone",
        label: formatRFCPhoneNumber(number, regionCode),
        regionCode,
        uri: formatRFCPhoneNumber(number, regionCode),
      },
    ],
    conferenceSolution: conferenceSolutions.phone,
    notes: createPhoneNumberNotes({
      phoneNumber: number,
      regionCode,
      firstName,
    }),
  };
}

function createPhoneNumberNotes({ phoneNumber, regionCode, firstName }) {
  if (!regionCode || !phoneNumber) {
    return "";
  }

  const displayNumber = formatPhoneNumberHumanReadable(phoneNumber, regionCode);
  if (!displayNumber) {
    return "";
  }

  return `Call ${firstName && `${firstName} at`} ${displayNumber}`;
}

export function createWhatsAppConferenceData({ currentUser, masterAccount }) {
  let whatsAppEntry = {
    entryPointType: "video",
    uri: `${WHATSAPP_BASE_URL}${getPhoneNumberString(
      currentUser.phone_number,
      currentUser.phone_region_code
    )}`,
    label: `WhatsApp ${formatRFCPhoneNumber(
      currentUser.phone_number,
      currentUser.phone_region_code
    )}`,
  };

  let entryPoints = [whatsAppEntry];

  const { firstName } = getUserName({ user: currentUser, masterAccount });

  let conferenceSolution = {
    key: {
      type: "addOn",
    },
    name: "WhatsApp Call",
    iconUri: WHATS_APP_ICON,
  };

  const notes = createWhatsAppNotes({
    firstName,
    phoneNumber: currentUser.phone_number,
    phoneRegionCode: currentUser.phone_region_code,
  });

  return { entryPoints, conferenceSolution, notes };
}

function createWhatsAppNotes({ firstName, phoneNumber, phoneRegionCode }) {
  if (!phoneRegionCode || !phoneNumber) {
    return "";
  }
  const displayNumber = formatRFCPhoneNumber(phoneNumber, phoneRegionCode);

  if (!displayNumber) {
    return "";
  }

  return `Call ${firstName} on WhatsApp (${displayNumber})`;
}

export function createCustomConferencing(currentUser) {
  const conferencingName = getCustomConferencingName({ user: currentUser });
  const conferencingUrl = getCustomConferencingURL({ user: currentUser });

  if (!isValidCustomConferencing(currentUser)) {
    return null;
  }

  const customEntry = {
    entryPointType: "video",
    uri: conferencingUrl,
    label: conferencingName,
  };

  const entryPoints = [customEntry];

  const conferenceSolution = {
    key: {
      type: "addOn",
    },
    name: conferencingName,
  };

  return { entryPoints, conferenceSolution, notes: "" };
}

export const EVENT_VISIBILILITY_OPTIONS = [
  { value: DEFAULT, label: DEFAULT_VISIBILITY },
  { value: PUBLIC, label: capitalizeFirstLetter(PUBLIC) },
  { value: PRIVATE, label: capitalizeFirstLetter(PRIVATE) },
];

export function createZoomNoteFromUniqueZoom(zoomMeeting) {
  const hostEmail = getZoomHostEmail(zoomMeeting);
  const joinURL = getZoomJoinURL(zoomMeeting);
  if (!hostEmail || !joinURL) {
    return null;
  }
  return `"Meeting host: <a href="mailto:${hostEmail}" target="_blank">${hostEmail}</a><br /><br />Join Zoom Meeting: <br /><a href="${joinURL}" target="_blank">${joinURL}</a>"`;
}

function createZoomConferencing({
  user,
  zoomMeeting,
  where,
  schedulers = {},
  masterAccount
}) {
  if (isDefaultZoomPersonalLink(user)) {
    return createZoomPersonalLinkConferenceData({ user, schedulers, masterAccount });
  } else if (isEmptyObjectOrFalsey(zoomMeeting)) {
    sendMessageToSentry(
      "Empty unique zoom meeting",
      where || "No location provided"
    );
    return createZoomPersonalLinkConferenceData({ user, schedulers, masterAccount });
  } else {
    let videoConferencingOption = {
      entryPointType: "video",
      uri: getZoomJoinURL(zoomMeeting),
      label: getZoomJoinURL(zoomMeeting),
      meetingCode: zoomMeeting.id,
      ...(zoomMeeting?.passcode && { passcode: zoomMeeting?.passcode }),
      ...(zoomMeeting?.password && { password: zoomMeeting?.password }),
    };

    let entryPoints = [videoConferencingOption];

    let { phoneInfo } = getZoomMeetingDetails(zoomMeeting);

    let phoneOption;
    if (phoneInfo) {
      let phoneNumber = formatRFCPhoneNumber(
        phoneInfo.number,
        phoneInfo.country
      );
      phoneOption = {
        entryPointType: "phone",
        label: phoneNumber,
        regionCode: phoneInfo.country,
        uri: `${phoneNumber},,${zoomMeeting.id}#`,
      };
      entryPoints = entryPoints.concat(phoneOption);
    }

    const zoomConference = {
      entryPoints,
      conferenceSolution: conferenceSolutions.zoom,
      id: zoomMeeting.id,
      notes: createZoomNoteFromUniqueZoom(zoomMeeting)
    };

    return zoomConference;
  }
}

export const RSVP_STATUS = {
  ATTENDING: ATTENDEE_EVENT_ATTENDING,
  NEEDS_ACTION: ATTENDEE_EVENT_NEEDS_ACTION,
  DECLINED: ATTENDEE_EVENT_DECLINED,
  MAYBE: ATTENDEE_EVENT_TENTATIVE,
};

// TODO and make sure we are not resetting it with lower case. We can also export the caps above directly and get rid of the lower case below
const GoogleCalendarService = {
  attendee_event_attending: ATTENDEE_EVENT_ATTENDING,
  attendee_event_tentative: ATTENDEE_EVENT_TENTATIVE,
  attendee_event_needs_action: ATTENDEE_EVENT_NEEDS_ACTION,
  attendee_event_declined: ATTENDEE_EVENT_DECLINED,
  self_response_confirmed: SELF_RESPONSE_CONFIRMED,
  self_response_tentative: ATTENDEE_EVENT_TENTATIVE,
  today: TODAY,
  previous: PREVIOUS,
  next: NEXT,
  freeDuringEvent: FREE_DURING_EVENT,
  busyDuringEvent: BUSY_DURING_EVENT,
  modifyEventsString: MODIFY_EVENTS_STRING,
  inviteOthersString: INVITE_OTHERS_STRING,
  seeGuestListString: SEE_GUEST_LIST_STRING,
  googleHangoutString: GOOGLE_HANGOUT_STRING,
  zoomString: ZOOM_STRING,
  noConferenceString: NO_CONFERENCE_STRING,
  phoneNumberConference: PHONE_NUMBER_CONFERENCE,
  minutesString: MINUTES_STRING,
  hoursString: HOURS_STRING,
  daysString: DAYS_STRING,
  dayString: DAY_STRING,
  weeksString: WEEKS_STRING,
  monthsString: MONTHS_STRING,
  yearsString: YEARS_STRING,
  confirmedAttendance: CONFIRMED_ATTENDANCE,
  after: AFTER,
  never: NEVER,
  on: ON,
  onDayX: ON_DAY_X,
  nthDayOfWeek: NTH_DAY_OF_WEEK,
  popup: POPUP,
  email: EMAIL,
  doesNotRepeat: DOES_NOT_REPEAT,
  daily: DAILY,
  weekly: WEEKLY,
  monthly: MONTHLY,
  annually: ANNUALLY,
  custom: CUSTOM,
  temporary: TEMPORARY,
  availability: AVAILABILITY,
  zoomUS: ZOOM_US,
  googleTimeFormat: TIME_FORMAT,
  selectTimeZone: SELECT_TIMEZONE,
  editableRoles: EDITABLE_ROLES,
  addAttendeePlaceholder: ADD_ATTENDEE_PLACEHOLDER,
  locationAutoComplete: LOCATION_AUTO_COMPLETE,
  takenLetterList: TAKEN_LETTER_LIST,
  allLetterList: ALL_LETTERS,
  visibilityPrivate: VISIBILITY_PRIVATE,
  busySummary: SUMMARY_BUSY,
  noTitle: NO_TITLE,
  EDIT_RECURRING_INSTANCE_ONLY,
  EDIT_RECURRING_FOLLOWING_EVENTS,
  EDIT_RECURRING_ALL_INSTANCES,
};

export default GoogleCalendarService;
