import _ from "underscore";
import {
  getEventID,
  getEventUpdatedAt,
} from "../services/eventResourceAccessors";
import { getEventUniqueKey, isGoogleEvent, isPreviewOutlookEvent } from "./eventFunctions";
import { isLocal } from "../services/devFunctions";
import { isEmptyArrayOrFalsey, isTypeString } from "../services/typeGuards";

/**
 * Returns all elements in the first array that are not present in the second array.
 */
export function getArrayDifference<T>(array1: T[] | null | undefined, array2: unknown[] | null | undefined) {
  if (isEmptyArrayOrFalsey(array1)) {
    return [];
  }

  if (isEmptyArrayOrFalsey(array2)) {
    return array1 ?? [];
  }

  return array1.filter((item) => !array2.includes(item));
}

/**
 * Odd cases to keep in mind:
 * - isEmptyArray("") => true
 * - isEmptyArray(null) => true
 * - isEmptyArray(undefined) => true
 * - isEmptyArray(false) => true
 * - isEmptyArray(0) => true
 *
 * Another variant of this function exists in typeChecks.ts and
 * explicitly checks that the value is an array.
 * @deprecated
 */
export function isEmptyArray(array) {
  return !array || array.length === 0;
}

export function reverseArray<T>(arr: T[]) {
  return arr.reverse();
}

export function splitArrayInHalf<T>(arr: T[]) {
  const middleIndex = Math.ceil(arr.length / 2);
  const firstHalf = arr.slice(0, middleIndex);
  const secondHalf = arr.slice(middleIndex);
  return [firstHalf, secondHalf];
}

// given array -> return "name, name, & name" for 3
// if 2: "name & name"
// if 1: "name"
export function formatNamesWithComma(array: string[] | null | undefined) {
  if (isEmptyArrayOrFalsey(array)) {
    return "";
  }

  let formattedString: string;

  if (array.length > 1) {
    formattedString =
      array.slice(0, -1).join(", ") + " & " + array[array.length - 1];
  } else {
    formattedString = array[0] || "";
  }
  return formattedString;
}

export function isStringArraysEqualIgnoringOrder(arr1: unknown[] | null | undefined, arr2: unknown[] | null | undefined) {
  if (!arr1) {
    // Returns true if both arrays are falsey.
    return !arr2;
  }
  if (!arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  if (arr1.length === 0 && arr2.length === 0) {
    return true;
  }
  const sortedArr1 = arr1.sort();
  const sortedArr2 = arr2.sort();
  return sortedArr1.join(",") === sortedArr2.join(",");
}

export function isStringArraysEqual(arr1: unknown[] | null | undefined, arr2: unknown[] | null | undefined) {
  if (!arr1) {
    // Returns true if both arrays are falsey.
    return !arr2;
  }
  if (!arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  if (arr1.length === 0 && arr2.length === 0) {
    return true;
  }
  return arr1.join(",") === arr2.join(",");
}

export function getLastArrayElement<T>(arr: T[] | null | undefined) {
  if (isEmptyArrayOrFalsey(arr)) {
    return null;
  }
  return arr[arr.length - 1];
}

export function isLastIndexOfArray({ index, arr }: { index: number, arr: unknown[] }) {
  return index === arr.length - 1;
}

// checks if there's multiple instances of string in array
export function hasMultipleOccurrencesOfStringInArray(arr: unknown[] | null | undefined, str: string) {
  if (isEmptyArrayOrFalsey(arr)) {
    return false;
  }
  const occurrences = arr.filter((item) => item === str);
  return occurrences.length >= 2;
}

export function isNotEmptyArry(arr: unknown[] | null | undefined) {
  return (arr?.length ?? 0) > 0;
}

export function getArrayLengthProtected(arr: unknown[] | null | undefined) {
  try {
    return arr?.length || 0;
  } catch (error) {
    return 0;
  }
}

/**
 * Shallow copy an array of objects and sort by a given key or path.
 */
export function safeSortObjects<T extends Record<string, any>>(arr: T[], path: string | (string | number)[], reverse = false) {
  const reverseFactor = reverse ? -1 : 1;

  // The type definition for _.get isn't perfect, need to force the path to be an array.
  const safePath = isTypeString(path) ? [path] : path;

  return [...arr].sort((a, b) => {
    const aCompare = _.get(a, safePath) as any;
    const bCompare = _.get(b, safePath) as any;

    if (aCompare < bCompare) {
      return -1 * reverseFactor;
    } else if (aCompare > bCompare) {
      return 1 * reverseFactor;
    }
    return 0;
  });
}

export function getEveryElementExceptLast<T>(arr: T[] | null | undefined) {
  if (isEmptyArrayOrFalsey(arr)) {
    return [];
  }
  return arr.slice(0, arr.length - 1);
}

interface MergeInPreviewOutlookEventsParams<T> {
  formattedEvents: T[] | null | undefined
  previewOutlookEvents: T[] | null | undefined
}

/**
 * TODO: This might fit better in eventFunctions.
 */
export function mergeInPreviewOutlookEvents<T>({
  formattedEvents,
  previewOutlookEvents,
}: MergeInPreviewOutlookEventsParams<T>) {
  // Create a map from the original array for quick lookup
  if (isEmptyArrayOrFalsey(formattedEvents)) {
    return previewOutlookEvents ?? [];
  }
  if (isEmptyArrayOrFalsey(previewOutlookEvents)) {
    return formattedEvents ?? [];
  }

  const eventsMapping = new Map(
    formattedEvents.map((event) => {
      if (isGoogleEvent(event)) {
        return [getEventUniqueKey(event), event]; // if we use eventID, it's going to filter out the same google event
      }
      return [getEventID(event), event];
    }),
  );

  previewOutlookEvents.forEach((previewEvent) => {
    const originalEvent = eventsMapping.get(getEventID(previewEvent));

    if (
      !originalEvent ||
      getEventUpdatedAt(previewEvent) > getEventUpdatedAt(originalEvent)
    ) {
      // If item doesn't exist in original or update is newer, update the map
      eventsMapping.set(getEventID(previewEvent), previewEvent);
    }
  });

  // Convert the map back to an array
  return Array.from(eventsMapping.values());
}

interface RemoveMatchingPreviewEventsParams<T> {
  formattedEvents: T[] | null | undefined
  updatedEvents: T[] | null | undefined
}

/**
 * TODO: This might fit better in eventFunctions.
 */
export function removeMatchingPreviewEvents<T>({
  formattedEvents,
  updatedEvents,
}: RemoveMatchingPreviewEventsParams<T>) {
  if (isEmptyArrayOrFalsey(updatedEvents)) {
    return formattedEvents ?? [];
  }
  if (isEmptyArrayOrFalsey(formattedEvents)) {
    return [];
  }

  // Create a map from the updates array for quick lookup
  const updatesMap = new Map(
    updatedEvents.map((event) => {
      if (isGoogleEvent(event)) {
        return [getEventUniqueKey(event), event]; // if we use eventID, it's going to filter out the same google event
      }
      return [getEventID(event), event];
    }),
  );

  // Filter the original array
  return formattedEvents.filter((event) => {
    // Check if the item is an event
    if (isPreviewOutlookEvent(event)) {
      // Get the corresponding update item
      const updateItem = updatesMap.get(getEventID(event));
      if (
        updateItem &&
        getEventUpdatedAt(event) <= getEventUpdatedAt(updateItem)
      ) {
        // If the update item exists and the original item's updated_at is < update item's updated_at, remove it
        return false;
      }
    }
    // Otherwise, keep the item
    return true;
  });
}

/**
 * Returns true if every element from the second array is in the first array.
 */
export function containsAllElements<T>(array1: unknown[] | null | undefined, array2: T[] | null | undefined) {
  if (isEmptyArrayOrFalsey(array1)) {
    return false;
  }
  const setArr1 = new Set(array1);
  return array2?.every(element => setArr1.has(element)) ?? false;
}

export function immutableDeleteElementAtIndex<T>(arr: T[] | null | undefined, index: number) {
  if (isEmptyArrayOrFalsey(arr)) {
    return [];
  }
  if (index < 0 || index >= arr.length) {
    if (isLocal()) {
      console.error("immutableDeleteElementAtIndex: Index out of bounds");
    }
    return arr;
  }
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function removeDuplicatesFromArray<T>(array: T[] | null | undefined) {
  if (isEmptyArrayOrFalsey(array)) {
    return [];
  }

  return Array.from(new Set(array));
}
