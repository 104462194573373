import * as Sentry from "@sentry/browser";
import { isLocal } from "../services/devFunctions";
import {
  sendErrorToTracking,
  shouldSkipSendingToSentryAndBigQuery,
  shouldSkipSendingToSentryButSendToBigQuery,
} from "../services/commonUsefulFunctions";
import { lowerCaseAndTrimStringWithGuard } from "./stringFunctions";
import { safeJSONStringify } from "./jsonFunctions";

const error_cache = {}; // so we only send one error per refresh
export function sendMessageAndDataToSentry(
  message: string,
  data: Record<string, unknown>,
  userEmail?: string | null,
) {
  if (isLocal()) {
    if (!shouldSendError(data)) {
      return; // ignore error
    }
    console.error("sendMessageAndDataToSentry message:", message);
    console.error("sendMessageAndDataToSentry data:", data);
  } else {
    if (!shouldSendError(data)) {
      return;
    }
    Sentry.captureMessage(message, {
      level: Sentry.Severity.Error,
      extra: {
        data,
      },
    });

    sendErrorToTracking(data, userEmail);
  }
}

export function shouldSendError(data) {
  try {
    const loweredCaseError = lowerCaseAndTrimStringWithGuard(data?.error);
    if (
      loweredCaseError.includes("report not ready") ||
      loweredCaseError.includes("domain not found")
    ) {
      // no point in sending this error
      return false;
    }
    if (shouldSkipSendingToSentryAndBigQuery(loweredCaseError)) {
      return false;
    }
    if (shouldSkipSendingToSentryButSendToBigQuery(loweredCaseError)) {
      return false;
    }
    const stringifiedData = safeJSONStringify(data) || "";
    if (stringifiedData && error_cache[stringifiedData]) {
      return false;
    }
    error_cache[stringifiedData] = true;
    return true;
  } catch (error) {
    return true;
  }
}
