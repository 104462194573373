import { localData } from "../services/commonUsefulFunctions";
import { getSignupAttribution } from "../services/queryParamFunctions";
import { LOCAL_DATA_ACTION } from "./localData";

const ATTRIBUTION_KEY = "a";
export function storeAttribution() {
  const attribution = getSignupAttribution();
  if (attribution) {
    localData(LOCAL_DATA_ACTION.SET, ATTRIBUTION_KEY, attribution);
  }
}

export function getStoredAttribution() {
  return localData(LOCAL_DATA_ACTION.GET, ATTRIBUTION_KEY);
}

export function clearStoredAttribution() {
  return localData(LOCAL_DATA_ACTION.DELETE, ATTRIBUTION_KEY);
}
